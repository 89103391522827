<!-- eslint-disable vue/this-in-template -->
<template>
  <div>
    <componente-ecac
      v-model="this.value"
    />
  </div>
</template>

<script>

export default {
  name: 'SecaoConfEcac',
  components: {
    ComponenteEcac: () => import('./components/ComponenteEcac.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>
